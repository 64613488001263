import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faAirConditioner,
	faArrowRightLong,
	faBath,
	faBed,
	faBenchTree,
	faBicycle,
	faBuildingColumns,
	faCalendarDays,
	faChalkboardUser,
	faChampagneGlasses,
	faChargingStation,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCircleParking,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faDeer,
	faDogLeashed,
	faEnvelope,
	faForkKnife,
	faGolfFlagHole,
	faHotTubPerson,
	faLink,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonBiking,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faRulerCombined,
	faShower,
	faSpa,
	faStore,
	faTaxi,
	faTree,
	faTv,
	faUsers,
	faUtensils,
	faVault,
	faWater,
	faWheelchair,
	faWifi,
	faWineGlass,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck, faBars, faLocationDot, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPlay, faSearch, faStar, faQuoteLeft, faStarHalf } from '@fortawesome/pro-solid-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faAirConditioner,
	faArrowRightLong,
	faBars,
	faBath,
	faBed,
	faBenchTree,
	faBicycle,
	faBuildingColumns,
	faCalendarDays,
	faChalkboardUser,
	faChampagneGlasses,
	faChargingStation,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faCircleParking,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faDeer,
	faDogLeashed,
	faEnvelope,
	faFacebook,
	faForkKnife,
	faGolfFlagHole,
	faHotTubPerson,
	faInstagram,
	faLink,
	faLinkedin,
	faLocationDot,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonBiking,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faPinterest,
	faPlay,
	faQuoteLeft,
	faRulerCombined,
	faSearch,
	faShower,
	faSoundcloud,
	faSpa,
	faSpotify,
	faStar,
	faStarHalf,
	faStore,
	faTaxi,
	faTiktok,
	faTimes,
	faTree,
	faTv,
	faTwitter,
	faUsers,
	faUtensils,
	faVault,
	faWater,
	faWhatsapp,
	faWheelchair,
	faWifi,
	faWineGlass,
	faYoutube,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
